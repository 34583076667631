/* src/animation/animation.scss */

.animation-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  height: 50vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  pointer-events: none;
  z-index: 1;
  opacity: 60%;

}

.spinning-ovals {
  width: 100%;
  height: 100%;
  position: relative;
}

.oval {
  width: 40vw;
  height: 60vh;
  border-radius: 50%;
  filter: blur(10rem); // Increase blur effect
  position: absolute;
  animation: organicMove 30s infinite alternate ease-in-out;
}

.oval1 {
  background-color: #0172af;
  top: 0;
  right: 25%;
}

.oval2 {
  background-color: #74febd;
  top: 25%;
  right: 0;
}

.oval3 {
  background-color: #c52233;
  top: 25%;
  right: 50%;
}

.oval4 {
  background-color: #ff8c00; // Added a fourth color (orange) to match the vibe
  top: 50%;
  right: 25%;
}

@keyframes organicMove {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(5vw, -5vh);
  }
  40% {
    transform: translate(-5vw, 5vh);
  }
  50% {
    transform: translateY(50vh); // Move to bottom
  }
  60% {
    transform: translate(10vw, -10vh);
  }
  80% {
    transform: translate(-10vw, 10vh);
  }
  100% {
    transform: translateY(-50vh); // Move back to top
  }
}
