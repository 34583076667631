/* src/components/Navbar.scss */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw; // Use vw instead of svw for compatibility
    padding: 1rem 2rem;
    /* background-color: #333; */
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    align-content: center;
    flex-wrap: nowrap; // Ensure items do not wrap
    flex-direction: row;
    z-index: 1000; // Ensure the navbar is on top
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    width:4.5rem;
  }
  
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem; // Add space between buttons
  }
  
  .nav-button {
    background-color: #555;
    border: none;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #777;
    }
  }
  
  .clear-button {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  